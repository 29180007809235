import useApiManager from 'networking/ApiManager';
import React, { useEffect, useMemo, useState } from 'react';
import CommonBorderBox from './CommonBorderBox';
import Loader from './Loader';
import WatiConfigForm from 'whealth-core-web/forms/WatiConfigForm';

function WatiConfig(props) {
  const { setIsShowAlert, setAlertType } = props;
  const ApiClient = useApiManager();
  const [data, setData] = useState([]);
  const [error, setError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [logo, setLogo] = useState('');

  useEffect(() => {
    getDashBoardConfigData();
  }, []);

  const prepateFormData = (data) => {
    const formData = new FormData();
    data.logo_url && formData.append('logo_url', data.logo_url);
    delete data.logo_url;
    formData.append('configuration', JSON.stringify(data));

    return formData;
  };

  const dashboardConfigration = () => {
    setError('');

    setIsLoading(true);
    if (!data?.wati_api_key) {
      data.wati_api_key = 'api-key';
    }
    if (!data?.wati_token_secret) {
      data.wati_token_secret = 'secret';
    }
    ApiClient.dashboardConfigration(prepateFormData(data))
      .then((res) => {
        setIsLoading(false);
        getDashBoardConfigData();
        if (isCreate) {
          setIsShowAlert('Configration created successfully.');
        } else {
          setIsShowAlert('Configration updated successfully.');
        }
        setAlertType('alert-success');
        setIsCreate(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setIsCreate(false);
        setAlertType('alert-danger');
        setIsShowAlert(err.response.data.detail);
      });
  };

  const getDashBoardConfigData = () => {
    setIsLoading(true);
    ApiClient.getDashBoardConfigData()
      .then((res) => {
        let tmpData = res.data.configuration;
        setIsCreate(!res.data.configuration);
        tmpData.getData = true;
        setData(res.data.configuration);
        setLogo(res.data.logo_url);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.response.data.errors);
      });
  };

  const renderWatiConfigForm = useMemo(() => {
    return (
      <div>
        <WatiConfigForm
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          error={error}
          valueObj={data}
          setValueObj={setData}
          onClick={dashboardConfigration}
          logo={logo}
        />
      </div>
    );
  }, [data, error]);

  return (
    <div className='medicine-form'>
      {renderWatiConfigForm}
      <Loader show={isLoading} />
    </div>
  );
}

export default WatiConfig;

import React, { useState, useEffect } from 'react';

import RenderHealthtab from 'whealth-core-web/components/RenderHealthtab';
import RenderTimelineTab from 'whealth-core-web/components/RenderTimelineTab';
import RenderGoalTab from 'whealth-core-web/components/RenderGoalTab';
import { CommonAlert, RowLinks } from 'whealth-core-web/components';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import RenderPrescriptionTab from 'whealth-core-web/components/RenderPrescriptionTab';
import { useDispatch, useSelector } from 'react-redux';
import { RenderTasksTab } from './index';
import RenderNotesTab from 'whealth-core-web/components/RenderNotesTab';
import { setPatientActiveTab } from 'redux/Slices';
import RenderReportTab from 'whealth-core-web/components/RenderReportTab';
import RenderQuestionTab from './patient/RenderQuestionTab';
import RenderSummaryTab from 'whealth-core-web/components/RenderSummaryTab';
import RenderDietPlan from 'whealth-core-web/components/patient/RenderDietPlan';
import useApiManager from 'networking/ApiManager';
import RenderTeleConsultation from './patient/RenderTeleConsultation';
import RenderConsultationTab from 'whealth-core-web/components/RenderConsultationTab';
import RenderService from 'whealth-core-web/RenderService';

function PatientActivityTab(props) {
  const navigate = useNavigate();
  const ApiClient = useApiManager();
  const { id } = useParams();

  const { data, getGraphData, setGoalData, getPatientData, activeNotesCallLog, setCallLogNotes } = props;
  const [activeIndex, setactiveIndex] = useState(0);
  const [showAlert, setshowAlert] = useState('');
  const [alertType, setAlertType] = useState('alert-danger');
  const { patientActiveTab } = useSelector((state) => state.sliceReducer);
  const doctorData = useSelector((state) => state?.sliceReducer?.patientDetails);
  const dispatch = useDispatch();

  useEffect(() => {
    if (activeNotesCallLog == 'notes') {
      setTabLinks('notes');
    }
  }, [activeNotesCallLog]);

  useEffect(() => {
    if (patientActiveTab) {
      getactiveInded();
      setCallLogNotes(false);
    }
  }, [patientActiveTab]);

  const getactiveInded = () => {
    headerData.map((item, index) => {
      if (item.link == patientActiveTab) {
        setactiveIndex(index);
      }
    });
  };

  const headerData = [
    {
      title: `Timeline `,
      link: 'timeline',
    },
    {
      title: `Health Profile `,
      link: 'health profile',
    },
    {
      title: `Prescription`,
      link: 'prescription',
    },
    {
      title: 'MDT Summary',
      link: 'summary',
      condition: 'enable_summary',
    },
    {
      title: `Goal`,
      link: 'goal',
    },
    {
      title: `Reports`,
      link: 'reports',
    },
    {
      title: `Notes`,
      link: 'notes',
    },
    {
      title: `Tasks`,
      link: 'task',
    },
    {
      title: `Conversion Log`,
      link: 'regime_question',
      condition: 'enable_regime_question',
    },
    {
      title: `Appointments`,
      link: 'appointments',
      condition: 'enable_appointment',
    },
    {
      title: `Diet Plan`,
      link: 'diet_plan',
      condition: 'enable_diet_plan',
    },
    {
      title: `Order Purchase`,
      link: 'order_purchase',
      condition: 'order_purchase',
    },
    {
      title: `Order`,
      link: 'order',
      condition: 'enable_order',
    },
    {
      title: `Tele Consultation`,
      link: 'tele_consultation',
      condition: 'enable_tele_consultation',
    },
    {
      title: `Service`,
      link: 'add_service',
      condition: 'enable_patient_add_service',
    },
  ];

  const newGoal = () =>
    navigate(`/patient/${data.id}/goals/new`, {
      state: {
        data,
      },
    });

  const uploadReport = () =>
    navigate(`/patient/${data.id}/reports/new`, {
      state: {
        data,
      },
    });

  const doctorAssign = doctorData?.admins?.find((item) => item.role == 'doctor' || item.role == 'doctor_pms');

  const newPrescription = () => {
    ApiClient.prescriptionDepartments(id)
      .then((res) => {
        if (res?.data?.length > 0) {
          navigate(`/patient/${data.id}/prescriptions/new`);
        } else {
          setshowAlert('No Department assigned');
        }
      })
      .catch((err) => {
        setshowAlert('No Department assigned');
      });
  };

  const newDiet = () => navigate(`/patient/${data.id}/dietPlan/new`);
  const newSummary = () => navigate(`/patient/${data.id}/summaries/new`);
  const newQuestions = () => navigate(`/patient/${data.id}/questions/new`);
  const newConsultation = () => {
    navigate(`/patient/${data.id}/appointment/new`, {
      state: {
        data,
      },
    });
  };
  const newTeleConsultation = () => navigate(`/patient/${data.id}/teleconsultation/new`);
  // const newService = () => navigate(`/patient/${data.id}/service/new`);
  const newService = () => navigate(`/patient/${data.id}/serviceDetails/new`);
  const setTabLinks = (link) => {
    dispatch(setPatientActiveTab(link));
  };

  const renderHeaderLinks = () => {
    return <RowLinks activeTab={activeIndex} data={headerData} onClickHandle={setTabLinks} getKey='link' />;
  };

  const renderTabs = () => {
    if (patientActiveTab === 'health profile') {
      return <RenderHealthtab setStatus={setTabLinks} />;
    }
    if (patientActiveTab === 'timeline') {
      return (
        <RenderTimelineTab
          data={data}
          getPatientData={getPatientData}
          getGraphData={getGraphData}
          setStatus={setTabLinks}
          newGoal={newGoal}
          newPrescription={newPrescription}
          newConsultation={newConsultation}
          uploadReport={uploadReport}
        />
      );
    }
    if (patientActiveTab === 'goal') {
      return (
        <RenderGoalTab
          getGraphData={getGraphData}
          data={data}
          setStatus={setTabLinks}
          newGoal={newGoal}
          setGoal={setGoalData}
        />
      );
    }
    if (patientActiveTab === 'prescription') {
      return (
        <RenderPrescriptionTab
          getPatientData={getPatientData}
          data={data}
          setStatus={setTabLinks}
          newPrescription={newPrescription}
        />
      );
    }
    if (patientActiveTab === 'task') {
      return <RenderTasksTab data={data} setStatus={setTabLinks} newPrescription={newPrescription} />;
    }
    if (patientActiveTab === 'notes') {
      return <RenderNotesTab data={data} setStatus={setTabLinks} newPrescription={newPrescription} />;
    }
    if (patientActiveTab === 'reports') {
      return <RenderReportTab data={data} setStatus={setTabLinks} uploadReport={uploadReport} />;
    }
    if (patientActiveTab === 'summary') {
      return <RenderSummaryTab data={data} setStatus={setTabLinks} newSummary={newSummary} />;
    }
    if (patientActiveTab === 'regime_question') {
      return (
        <RenderQuestionTab
          data={data}
          setStatus={setTabLinks}
          uploadReport={uploadReport}
          newQuestions={newQuestions}
        />
      );
    }
    if (patientActiveTab === 'appointments') {
      return <RenderConsultationTab data={data} newConsultation={newConsultation} isNivanCare={true} />;
    }
    if (patientActiveTab === 'diet_plan') {
      return <RenderDietPlan data={data} setStatus={setTabLinks} uploadReport={uploadReport} newQuestions={newDiet} />;
    }
    if (patientActiveTab === 'tele_consultation') {
      return (
        <RenderTeleConsultation
          data={data}
          setStatus={setTabLinks}
          uploadReport={uploadReport}
          newQuestions={newTeleConsultation}
        />
      );
    }
    if (patientActiveTab === 'add_service') {
      return (
        <RenderService data={data} setStatus={setTabLinks} uploadReport={uploadReport} newQuestions={newService} />
      );
    }
  };

  return (
    <>
      {renderHeaderLinks()}
      {showAlert && (
        <div className='mt-5'>
          <CommonAlert isShowAlert={showAlert} setIsShowAlert={setshowAlert} alertType={alertType} />
        </div>
      )}
      {renderTabs()}
    </>
  );
}

export default PatientActivityTab;

import React, { useEffect, useState } from 'react';
import './AppointmentVer2.scss';
import { DatePicker } from 'rsuite';
import Select2DropDown from '../common/Select2DropDown';
import useApiManager from 'networking/ApiManager';
import CoreInputBox from '../CoreInputBox';
import { dateFormatStringSingleDate, timeFormat } from '../Helper';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetCockpitDataQuery } from 'redux/cockpitSlice';
import CommonRenderCheckBox from '../CommonRenderCheckBox';
import CoreCheckBox from '../CoreCheckBox';

function AppointmentForm(props) {
  let environment = process.env.REACT_APP_ENVIRONMENT;
  let serviceID = 3;
  let userID = 2676;
  let {
    createField,
    isBabyMd,
    setCreateField,
    handleClinicSearch,
    allClinic,
    searchClinicList,
    onClick,
    errors,
    setIsLoading,
    disablePatient,
    hideRecord,
    appointmentID,
    appointmentDetail,
    isClinicEnable,
    activeDate,
    isNivanCare,
  } = props;
  const ApiClient = useApiManager();
  const getCockpitData = useGetCockpitDataQuery();
  let configData = getCockpitData?.data?.data?.business_config;
  const [patientList, setPatientList] = useState([]);
  const [searchPatientList, setSearchPatientList] = useState([]);
  const [searchPatientStr, setSearchPatientStr] = useState('');
  const [doctorList, setDoctorList] = useState([]);
  const [searchDocStr, setSearchDocStr] = useState('');
  const [searchDoctorList, setSearchDoctorList] = useState([]);
  const [clinicList, setClinicList] = useState([]);
  const [searchClinicStr, setSearchClinicStr] = useState('');
  const [doctorServices, setDoctorServices] = useState([]);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [appointmentDate, setAppointmentDate] = useState(null);
  const [appointmentFrom, setAppointmentFrom] = useState(null);
  const [appointmentTo, setAppointmentTo] = useState(null);
  const [showAllSlots, setShowAllSlots] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [filteredDoctorList, setFilteredDoctorList] = useState('');
  const [newDefaultService, setServiceDefaultVal] = useState('');
  const [isBreak, setIsBreak] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isActiveSlots = (index) => {
    return index === activeIndex;
  };
  useEffect(() => {
    getPatients();
  }, []);

  useEffect(() => {
    if (createField?.service_id == serviceID) {
      setIsBreak(true);
    } else {
      setIsBreak(false);
    }
  }, [createField?.service_id]);

  useEffect(() => {
    if (activeDate) {
      setAppointmentDate(new Date(activeDate));
    }
  }, [activeDate]);

  useEffect(() => {
    if (allClinic?.length) {
      setClinicList(allClinic);
    }
  }, [allClinic]);

  useEffect(() => {
    if (location?.state?.patientName) setCreateField({ ...createField, user_id: location?.state?.patientName });
  }, [location?.state?.patientName]);

  useEffect(() => {
    if ((createField.consult_type == null || createField.consult_type == '') && isBabyMd) {
      setCreateField({
        ...createField,
        consult_type: 'physical',
      });
    }
  }, [isBabyMd]);

  useEffect(() => {
    if (clinicList.length == 1 && isBabyMd) {
      setCreateField({
        ...createField,
        location_id: clinicList[0]?.id,
      });
    }
  }, [clinicList]);

  useEffect(() => {
    if (newDefaultService && isBabyMd) {
      setCreateField({
        ...createField,
        service_id: newDefaultService?.id,
      });
    }
  }, [newDefaultService]);

  useEffect(() => {
    if (filteredDoctorList.length == 1 && isBabyMd) {
      setCreateField({
        ...createField,
        consultant_id: filteredDoctorList[0]?.id,
      });
    }
  }, [filteredDoctorList]);

  useEffect(() => {
    if (appointmentDetail?.service?.id && doctorServices?.findIndex((item1) => item1?.id == appointmentDetail?.service?.id) == -1) {
      doctorServices.push(appointmentDetail?.service);
      setDoctorServices([...doctorServices]);
    }
  }, [appointmentDetail?.service?.id, doctorServices]);

  useEffect(() => {
    if (appointmentDetail?.consultant?.id && doctorList?.findIndex((item1) => item1?.id == appointmentDetail?.consultant?.id) == -1) {
      doctorList.push(appointmentDetail?.consultant);
      setDoctorList([...doctorList]);
    }
  }, [appointmentDetail?.consultant?.id, doctorList]);

  useEffect(() => {
    if (createField?.location_id || !isClinicEnable) {
      getDoctor();
    } else {
      setDoctorList([]);
    }
  }, [createField?.location_id, isClinicEnable]);

  useEffect(() => {
    let patientData = [...patientList];
    if (appointmentDetail?.user?.id && patientData?.findIndex((item3) => item3?.id == appointmentDetail?.user?.id) == -1) {
      patientData.push(appointmentDetail?.user);
      setPatientList(patientData);
    }
  }, [patientList, appointmentDetail?.user?.id]);

  const getPatients = () => {
    setIsLoading(true);
    ApiClient.getSearchPatients(searchPatientStr)
      .then((res) => {
        setIsLoading(false);
        let patientData = res.data;
        setPatientList(patientData);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  useEffect(() => {
    if (createField?.consultant_id && createField?.location_id && appointmentDate) {
      getAvailableSlots();
    } else {
      setAvailableSlots([]);
    }
  }, [createField?.consultant_id, createField?.location_id, appointmentDate]);

  useEffect(() => {
    if (createField?.consult_datetime) {
      setAppointmentDate(new Date(createField?.consult_datetime));
    }
    if (createField?.consult_end_datetime) {
      setAppointmentDate(new Date(createField?.consult_end_datetime));
    }
  }, [createField?.consult_datetime, createField?.consult_end_datetime]);

  const getAvailableSlots = () => {
    let params = {
      doctor_id: createField?.consultant_id,
      date: dateFormatStringSingleDate(appointmentDate),
      location_id: createField?.location_id,
    };
    setIsLoading(true);
    ApiClient.getAvailableSlots(params)
      .then((res) => {
        setIsLoading(false);
        setAvailableSlots(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const handlePatientSearch = (search_str) => {
    if (search_str?.length > 2) {
      setSearchPatientStr(search_str);
      setIsLoading(true);
      ApiClient.getSearchPatients(search_str)
        .then((res) => {
          setIsLoading(false);
          setSearchPatientList(res.data.length > 0 ? res.data : [{ id: null, full_name: `Add Your New Patient: ${search_str}`, phone: '' }]);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    } else {
      setSearchPatientStr('');
      setSearchPatientList([]);
    }
  };

  const getDoctor = () => {
    setIsLoading(true);
    if (createField?.location_id && isClinicEnable) {
      ApiClient.getClinicAdmin(createField?.location_id, { "role":"doctor"})
        .then((res) => {
          setIsLoading(false);
          setDoctorList(res.data);
          const filteredDoctorList = res?.data?.filter((item4) => item4?.role == 'doctor');
          setFilteredDoctorList(filteredDoctorList);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      let params = { role: 'doctor', remove_inactive_admins: true };
      ApiClient.getSearchTeam(params)
        .then((res) => {
          setIsLoading(false);
          setDoctorList(res.data);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  const handleDoctorSearch = (search_str) => {
    if (search_str?.length > 2) {
      setSearchDocStr(search_str);
      if (createField?.location_id && isClinicEnable) {
        setIsLoading(true);
        let params = { search_str };
        ApiClient.getClinicAdmin(createField?.location_id, params)
          .then((res) => {
            setIsLoading(false);
            setSearchDoctorList(res.data);
          })
          .catch((err) => {
            setIsLoading(false);
          });
      } else {
        setIsLoading(true);
        let params = { search_str, role: 'doctor', remove_inactive_admins: true };
        ApiClient.getSearchTeam(params)
          .then((res) => {
            setIsLoading(false);
            setSearchDoctorList(res.data);
          })
          .catch((err) => {
            setIsLoading(false);
          });
      }
    } else {
      setSearchDocStr('');
      setSearchDoctorList([]);
    }
  };

  useEffect(() => {
    if (createField?.consultant_id) {
      getDoctorServices(createField?.consultant_id);
    }
  }, [createField?.consultant_id]);

  const getDoctorServices = (ids) => {
    setIsLoading(true);
    ApiClient.getDocServices(ids)
      .then((res) => {
        setIsLoading(false);
        setDoctorServices(res.data);
        const newDefaultService = res.data.find((item) => item.title == 'Consultation');
        setServiceDefaultVal(newDefaultService);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const toggleShowAllSlots = () => {
    setShowAllSlots(!showAllSlots);
  };
  const renderDuration = () => {
    let duration =
      new Date(createField?.consult_end_datetime).getHours() * 60 +
      new Date(createField?.consult_end_datetime).getMinutes() -
      (new Date(createField?.consult_datetime).getHours() * 60 + new Date(createField?.consult_datetime).getMinutes());
    let durationHours = parseInt(duration / 60);
    let durationMinutes = duration % 60;
    let value = '';
    if (durationHours > 0) {
      value += durationHours + ' Hours ';
    }
    if (durationMinutes > 0) {
      value += durationMinutes + ' Mins';
    }
    value = createField?.consult_datetime && createField?.consult_end_datetime ? value : '';
    return <CoreInputBox labelStyle={{ fontSize: 10 }} label='Duration' disabled value={value} />;
  };

  return (
    <div className='appontmentModle-body d-flex flex-column'>
      {isNivanCare && props?.userData?.role != 'ee' && (!appointmentID || (appointmentID && appointmentDetail?.service?.id == serviceID)) ? (
        <div className='mb-3 d-flex w-100 align-items-center  checkBoxBtn'>
          <CoreCheckBox
            disabled={appointmentID}
            onChange={(value, checked) => {
              setIsBreak(checked);
              setCreateField(
                checked
                  ? {
                      ...createField,
                      user_id: userID,
                      consult_type: 'physical',
                      service_id: serviceID,
                      appointment_fee: 0,
                    }
                  : {
                      ...createField,
                      user_id: null,
                      consult_type: null,
                      service_id: null,
                      appointment_fee: null,
                    }
              );
            }}
            checked={isBreak}
            type='checkbox'
            class='btn-check'
            id='btn-check-2-outlined'
            autocomplete='off'
          />
          <label for='btn-check-2-outlined'>
            <strong className='pointer'>Add Break</strong>
          </label>
        </div>
      ) : null}
      {!isBreak && (
        <div className='mb-2'>
          <Select2DropDown
            isValidation
            disabled={disablePatient}
            retuired
            data={searchPatientStr?.length ? searchPatientList : patientList}
            onSelect={(_id, _obj) => {
              if (_id == null) {
                navigate('/patient/new', { state: { key: 'appointment', name: searchPatientStr } });
              }
              if (_obj?.id && patientList?.findIndex((item2) => item2?.id == _obj?.id) == -1) {
                patientList.push(_obj);
                setPatientList([...patientList]);
              }
              setSearchPatientStr('');
              setCreateField({ ...createField, user_id: _obj.id });
            }}
            onSearch={(searchStr) => {
              handlePatientSearch(searchStr);
            }}
            searchBy={(keyword, label, item) => {
              return item?.phone?.includes(keyword) || item?.full_name?.toLowerCase()?.includes(keyword.toLowerCase());
            }}
            showMSG={errors?.user_id}
            value={createField?.user_id || ''}
            label={`Patient's Name or Phone No.`}
            labelKey={'full_name'}
            searchable={true}
            style={{ width: '100%' }}
            placeholder={`Patient's Name or Phone No.`}
          />
        </div>
      )}

      {!isBreak && (
        <div className='mb-2'>
          <Select2DropDown
            retuired
            searchable={false}
            label={'Appointment Type'}
            value={createField?.consult_type || ''}
            data={[
              { title: 'Physical', id: 'physical' },
              { title: 'Virtual', id: 'virtual' },
            ]}
            showMSG={errors?.consult_type}
            onSelect={(val) =>
              setCreateField({
                ...createField,
                consult_type: val == '' ? null : val,
                meeting_link: null,
              })
            }
          />
        </div>
      )}
      {createField.consult_type == 'physical' && isBabyMd && (
        <div className='mb-2'>
          <CommonRenderCheckBox
            label='Walk In'
            id='flexCheckDefault8'
            htmlFor='flexCheckDefault8'
            onChange={(data) => {
              setCreateField({
                ...createField,
                walk_in: !createField?.walk_in,
              });
            }}
            checked={createField?.walk_in}
          />
        </div>
      )}
      {/* <div className='mb-2'>
        <Select2DropDown
          retuired
          searchable={false}
          label={'Appointment Type'}
          value={createField?.walk_in || ''}
          data={[{ title: 'Walk In', id: 'walk_in' }]}
          showMSG={errors?.consult_type}
          onSelect={(val) =>
            setCreateField({
              ...createField,
              walk_in: val,
            })
          }
        />
      </div> */}
      {isClinicEnable ? (
        <div className='mb-2'>
          <Select2DropDown
            isValidation
            retuired
            data={searchClinicStr?.length ? [...new Map([...searchClinicList, ...clinicList].map((item) => [item?.id, item])).values()] : clinicList}
            onSelect={(_id, _obj) => {
              if (_obj?.id && clinicList?.findIndex((item2) => item2?.id == _obj?.id) == -1) {
                clinicList.push(_obj);
                setClinicList([...clinicList]);
              }
              setSearchClinicStr('');
              setCreateField({ ...createField, location_id: _obj?.id || null, consultant_id: null });
            }}
            onSearch={(searchStr) => {
              setSearchClinicStr(searchStr);
              handleClinicSearch(searchStr);
            }}
            onClean={() => {
              setSearchClinicStr('');
              setCreateField({ ...createField, location_id: null, consultant_id: null });
            }}
            value={createField?.location_id || ''}
            showMSG={errors?.location_id}
            label={'Clinic'}
            labelKey={'name'}
            searchable={true}
            style={{ width: '100%' }}
            placeholder='Clinic'
          />
        </div>
      ) : null}

      <div className='mb-2'>
        <Select2DropDown
          isValidation
          retuired
          data={
            searchDocStr?.length
              ? searchDoctorList?.filter((item4) => item4?.role == 'doctor' || item4?.role == 'doctor_pms')
              : doctorList?.filter((item4) => item4?.role == 'doctor' || item4?.role == 'doctor_pms')
          }
          onSelect={(_id, _obj) => {
            if (_obj?.id && doctorList?.findIndex((item2) => item2?.id == _obj?.id) == -1) {
              doctorList.push(_obj);
              setDoctorList([...doctorList]);
            }
            setSearchDocStr('');
            setCreateField({ ...createField, consultant_id: _id });
          }}
          onSearch={(searchStr) => {
            handleDoctorSearch(searchStr);
          }}
          onClean={() => {
            setSearchDocStr('');
            setCreateField({ ...createField, consultant_id: null });
          }}
          value={createField?.consultant_id || ''}
          showMSG={errors?.consultant_id}
          labelKey={'full_name'}
          searchable={true}
          style={{ width: '100%' }}
          placeholder='Doctor'
          label='Doctor'
        />
      </div>
      {configData?.findIndex((configItem) => configItem?.key == 'enable_meeting_link' && !configItem?.isActive) != -1 &&
      createField.consult_type == 'virtual' &&
      !isBreak ? (
        <div className='mb-2'>
          <CoreInputBox
            // retuired
            value={createField?.meeting_link || ''}
            placeholder='Meeting Link'
            label='Meeting Link'
            setValue={(val) => {
              setCreateField({ ...createField, meeting_link: val });
            }}
            showMSG={errors?.meeting_link}
          />
        </div>
      ) : null}
      {!isBreak && (
        <div className='mb-2'>
          <Select2DropDown
            retuired
            onSelect={(id) => {
              setCreateField({ ...createField, service_id: id });
            }}
            value={createField?.service_id || ''}
            data={doctorServices}
            showMSG={errors?.service_id}
            searchable={false}
            label='Service'
          />
        </div>
      )}
      <div className='mb-2'>
        <label>
          <span className='requiredInput'> * </span> Date
        </label>
        <br />
        <DatePicker
          onSelect={(date) => {
            setAppointmentDate(new Date(date));
          }}
          className='w-100'
          onClean={() => {
            setAppointmentDate(null);
            setAppointmentFrom(null);
            setAppointmentTo(null);
            setAvailableSlots([]);
            setCreateField({ ...createField, consult_datetime: null, consult_end_datetime: null });
          }}
          value={appointmentDate}
        />
      </div>
      <div className='mb-2 '>
        <label>Time</label>
        <div className='d-flex justify-content-between gap-10'>
          <div className='w-100 d-flex flex-column'>
            <div>{renderDuration()}</div>
          </div>
          <div className='w-100  d-flex flex-column'>
            <label style={{ fontSize: '10px' }} className='inputlabel'>
              <span className='requiredInput'> * </span> From
            </label>
            <DatePicker
              format='hh:mm a'
              showMeridian={true}
              // disabledHours={(hour) => {
              //   if (hour > new Date(createField?.consult_end_datetime).getHours()) {
              //     return true;
              //   } else {
              //     return false;
              //   }
              // }}
              // disabledMinutes={(min, date) => {
              //   if (
              //     new Date(date).getHours() >= new Date(createField?.consult_end_datetime).getHours() &&
              //     min > new Date(createField?.consult_end_datetime).getMinutes()
              //   ) {
              //     return true;
              //   } else {
              //     return false;
              //   }
              // }}
              hideMinutes={(minute) => (isBabyMd ? minute % 15 !== 0 : null)}
              onClean={(e) => {
                setAppointmentFrom(null);
                setCreateField({ ...createField, consult_datetime: null });
              }}
              onSelect={(time) => {
                let consultStartTime = new Date(appointmentDate);
                consultStartTime.setHours(new Date(time).getHours(), new Date(time).getMinutes());
                setAppointmentFrom(new Date(consultStartTime));
                setCreateField({ ...createField, consult_datetime: new Date(consultStartTime).toISOString() });
              }}
              disabled={(!createField?.consult_datetime && !appointmentDate) || props?.userData?.role == 'ee'}
              value={createField?.consult_datetime ? new Date(createField?.consult_datetime) : appointmentFrom}
            />
            {errors?.consult_datetime ? (
              <div className='inputMsg' style={{ color: 'red' }}>
                {errors?.consult_datetime[0]}
              </div>
            ) : null}
          </div>
          <div className='w-100  d-flex flex-column'>
            <label style={{ fontSize: '10px' }} className='inputlabel'>
              <span className='requiredInput'> * </span> To
            </label>
            <DatePicker
              format='hh:mm a'
              showMeridian
              hideMinutes={(minute) => (isBabyMd ? minute % 15 !== 0 : null)}
              // disabledHours={(hour) => {
              //   if (hour < new Date(createField?.consult_datetime).getHours()) {
              //     return true;
              //   } else {
              //     return false;
              //   }
              // }}
              // disabledMinutes={(min, date) => {
              //   if (
              //     new Date(date).getHours() <= new Date(createField?.consult_datetime).getHours() &&
              //     min < new Date(createField?.consult_datetime).getMinutes()
              //   ) {
              //     return true;
              //   } else {
              //     return false;
              //   }
              // }}
              onClean={(e) => {
                setAppointmentTo(null);
                setCreateField({ ...createField, consult_end_datetime: null });
              }}
              onSelect={(time) => {
                let consultStartTime = new Date(appointmentDate);
                consultStartTime.setHours(new Date(time).getHours(), new Date(time).getMinutes());
                setAppointmentTo(new Date(consultStartTime));
                setCreateField({ ...createField, consult_end_datetime: new Date(consultStartTime).toISOString() });
              }}
              disabled={(!createField?.consult_end_datetime && !appointmentDate) || props?.userData?.role == 'ee'}
              value={createField?.consult_end_datetime ? new Date(createField?.consult_end_datetime) : appointmentTo}
            />
            {errors?.consult_end_datetime ? (
              <div className='inputMsg' style={{ color: 'red' }}>
                {errors?.consult_end_datetime[0]}
              </div>
            ) : null}
          </div>
        </div>
        <div className='d-flex justify-content-between gap-10'></div>
        <br />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {errors?.appointment ? (
            <div className='inputMsg' style={{ color: 'red' }}>
              {errors?.appointment[0]}
            </div>
          ) : null}
        </div>
      </div>
      {isClinicEnable ? (
        <div className='mb-2'>
          <label className='mb-1' style={{ fontSize: '10px' }}>
            Recommended Time Slots
          </label>
          {isBabyMd ? (
            <div className='d-flex gap-10 justify-content-start flex-wrap'>
              {availableSlots?.slice(0, showAllSlots ? availableSlots.length : 10).map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`slotTag text-nowrap ${isActiveSlots(index) ? 'active' : ''}`}
                    onClick={() => {
                      setCreateField({
                        ...createField,
                        consult_datetime: new Date(item[0]).toISOString(),
                        consult_end_datetime: new Date(item[1]).toISOString(),
                      });
                      setActiveIndex(index);
                    }}
                  >
                    {timeFormat(item[0])} - {timeFormat(item[1])}
                  </div>
                );
              })}
              {availableSlots.length > 10 && (
                <div onClick={toggleShowAllSlots} className='d-flex w-100 justify-content-center mt-2 slotTag'>
                  <span class='material-icons-outlined'>{showAllSlots ? 'expand_less' : 'expand_more'}</span>
                  {showAllSlots ? 'View Less' : 'View More'}
                </div>
                // <button onClick={toggleShowAllSlots}>{showAllSlots ? 'View Less' : 'View More'}</button>
              )}
            </div>
          ) : (
            <div className='d-flex gap-10 justify-content-start  flex-wrap'>
              {availableSlots?.map((item) => {
                return (
                  <div
                    className='slotTag  text-nowrap '
                    onClick={() => {
                      setCreateField({
                        ...createField,
                        consult_datetime: new Date(item[0]).toISOString(),
                        consult_end_datetime: new Date(item[1]).toISOString(),
                      });
                    }}
                  >
                    {timeFormat(item[0])} - {timeFormat(item[1])}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      ) : null}

      {!isBreak && (
        <div className='mb-2'>
          <CoreInputBox
            retuired
            value={createField?.appointment_fee || ''}
            placeholder='Appointment Fee'
            label='Appointment Fee'
            setValue={(val) => {
              setCreateField({ ...createField, appointment_fee: val });
            }}
            disabled={appointmentDetail?.patient_attendance_status}
            showMSG={errors?.appointment_fee}
          />
        </div>
      )}
      <div className='mb-2 mt-3'>
        <div className='d-flex justify-content-between gap-10 schedulButton'>
          <div
            className='reusableBtnActive  reusableBtn  w-100'
            onClick={() => {
              onClick(createField);
            }}
          >
            {appointmentID ? 'Update' : 'Create'} {isBreak ? ' Break' : ' Appointment'}
          </div>
        </div>
        {!hideRecord && !isBreak ? (
          <div className='d-flex justify-content-between gap-10 schedulButton mt-4'>
            <div
              className='borderBtn  w-100'
              onClick={() => {
                onClick(createField, true);
              }}
            >
              {appointmentID ? 'Update' : 'Create'} Appointment & Record Payment
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default AppointmentForm;

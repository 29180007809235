import useApiManager from 'networking/ApiManager';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { adherenceIcon, carePlan, editTheme, email, whatsapp } from 'res/images';
import CommonToolTip from 'whealth-core-web/components/CommonToolTip';
import DateCards from 'whealth-core-web/components/DateCards';
import {
  dateFormat,
  dateFormatString,
  handleChannel,
  profileImage,
  showArrayValues,
  teamRoles,
  timeFormat,
  titlizeString,
} from 'whealth-core-web/components/Helper';
import StatusCard from 'whealth-core-web/components/StatusCard';
import StatusTag from 'whealth-core-web/components/StatusTag';
import inputFields from '../inputFields.json';
import { Select2DropDown } from 'whealth-core-web/components';
import { Dropdown } from 'rsuite';
import CallLog from 'whealth-core-web/components/CallLog/CallLog';
import { useGetCockpitDataQuery } from 'redux/cockpitSlice';
import { useDispatch, useSelector } from 'react-redux';
import { setMedicineActiveCard } from 'redux/Slices';
import { useGetLoginUserDataQuery } from 'redux/apiSlice';

function PatientProfile(props) {
  const { data, getPatientData, setCallLogNotes } = props;
  const [editStatus, setEditStatus] = useState(false);
  const [editSubStatus, setEditSubStatus] = useState(false);
  const [editOnboardStatus, setEditOnboardStatus] = useState(false);
  const [status, setStatus] = useState([]);
  const [subStatus, setSubStatus] = useState([]);
  const [callLogData, setCallLogData] = useState();
  const [reportData, setReportData] = useState([]);
  const [notesData, setNotesData] = useState([]);
  const [prescriptionData, setPrescriptionData] = useState([]);
  const [goalData, setGoalData] = useState([]);
  const [behaviourQuetionData, setbehaviourQuetionData] = useState([]);
  let userData = useGetLoginUserDataQuery()?.data?.data || {};

  const [currentPage, setCurrentPage] = useState(1);
  const [callLogApiCalled, setCallLogApiCalled] = useState(false);
  const dispatch = useDispatch();
  const { reduxReportData, reduxNotesData, reduxPrescriptionData, reduxGoalData, reduxRegimeData } = useSelector((state) => state.sliceReducer);
  const profileQuestionkeys = 'pms_consult_date';
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const ApiClient = useApiManager();
  const [isCallLog, setIsCallLog] = useState(false);
  const getCockpitData = useGetCockpitDataQuery();
  let configData = getCockpitData?.data?.data?.business_config;
  const { id } = useParams();
  useEffect(() => {
    getRegimeData();
    getMedicalHistory();
    if (configData) {
      configData.map((item) => {
        if (item.key == 'call_log' && item.isActive) {
          setIsCallLog(item.isActive);
        }
      });
    }
  }, [configData]);

  const getCallLog = () => {
    // setIsLoading(true);
    ApiClient.getCallLog(id)
      .then((res) => {
        setCallLogData(res.data);
        // setIsLoading(false);
        // setTotalPages(res.headers['total-pages']);
      })
      .catch((err) => {
        // setIsLoading(false);
      });
  };

  const getPatientReport = () => {
    // setIsLoading(true);
    let params = { page: currentPage };
    ApiClient.getPatientReports(id, false, params)
      .then((res) => {
        setReportData(res.data);
        // setIsLoading(false);
      })
      .catch((err) => {
        // setIsLoading(false);
      });
  };

  const getNotes = () => {
    // setIsLoading(true);
    let params = { page: currentPage };
    ApiClient.getNotes(id, params)
      .then((res) => {
        // setIsLoading(false);
        setNotesData(res.data);
      })
      .catch((err) => {
        // setIsLoading(false);
      });
  };

  const getPatientGoals = () => {
    // setLoading(true);
    let params = { page: currentPage, date_range: dateFormatString(new Date()), category: 'default' };
    ApiClient.getPatientgoals(id, false, params)
      .then((res) => {
        setGoalData(res.data);
        // setLoading(false);
      })
      .catch((err) => {
        // setLoading(false);
      });
  };

  const getRegimeData = () => {
    // setIsLoading(true);
    let params = { page: currentPage };
    ApiClient.behaviourQuestionList(id, params)
      .then((res) => {
        // setIsLoading(false);
        setbehaviourQuetionData(res.data);
      })
      .catch((err) => {
        // setIsLoading(false);
        console.log(err);
      });
  };

  const getMedicalHistory = (page = currentPage) => {
    // setIsLoading(true);
    let params = { page };
    ApiClient.prescriptionList(id, params)
      .then((res) => {
        // setIsLoading(false);
        dispatch(setMedicineActiveCard(res.data.filter((item) => item.status === 'published')));
        setPrescriptionData(res.data.filter((item) => item.status === 'published'));
      })
      .catch((err) => {
        // setIsLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setEditStatus(false);
        setEditSubStatus(false);
        setEditOnboardStatus(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
  }, []);

  useEffect(() => {
    if (data?.status?.title) {
      setSubStatus(inputFields.subStatus[data?.status?.title]);
    }
  }, [data?.status?.title]);

  useEffect(() => {
    if (status?.length == 0) {
      getPatientStatus();
    }
  }, []);

  const getPatientStatus = () => {
    const type = { type: 'patient' };
    ApiClient.allStatus(type)
      .then((res) => {
        setStatus(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const renderPatientInfo = () => {
    return (
      <div className='card-body'>
        <div className='d-flex user-profile-card'>
          <div className='me-3 d-flex flex-column'>
            <img className='rounded float-start img-fluid me-3' src={profileImage(data.gender, data.profile_url)} />
            {isCallLog && (
              <div className=' m-2 ms-4 profile-call-log-btn'>
                <button
                  class='call-log-btn'
                  type='button'
                  data-bs-toggle='offcanvas'
                  data-bs-target='#offcanvasRight'
                  aria-controls='offcanvasRight'
                  onClick={() => {
                    if (!callLogApiCalled) {
                      reduxNotesData?.length == 0 && getNotes();
                      reduxGoalData?.length == 0 && getPatientGoals();
                      reduxReportData?.length == 0 && getPatientReport();
                      reduxPrescriptionData?.length == 0 && getMedicalHistory();
                      getCallLog();
                      setCallLogApiCalled(true);
                    }
                  }}
                >
                  Call Log
                </button>
                <div class='offcanvas offcanvas-end callLog-Part' tabindex='-1' id='offcanvasRight' aria-labelledby='offcanvasRightLabel'>
                  <CallLog
                    setCallLogNotes={setCallLogNotes}
                    data={data}
                    goalData={reduxGoalData.length ? reduxGoalData : goalData}
                    prescriptionData={reduxPrescriptionData.length ? reduxPrescriptionData : prescriptionData}
                    notesData={reduxNotesData.length ? reduxNotesData : notesData}
                    reportData={reduxReportData?.length ? reduxReportData : reportData}
                    callLogData={callLogData}
                    behaviourQuetionData={behaviourQuetionData}
                    setCallLogData={setCallLogData}
                    getRegimeData={getRegimeData}
                  />
                </div>
              </div>
            )}
          </div>
          <div className='w-75'>
            <div className='border-bottom pb-3'>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <h5 className='card-title capitalize'>{data?.full_name}</h5>
                <div className='pointer' onClick={() => navigate(`/patient/${data.id}/edit`)}>
                  <CommonToolTip placement={'top'} item={<img src={editTheme} />} showValue={'Edit'} />
                </div>
              </div>
              <p className='card-text capitalize'>{showArrayValues([showArrayValues([data.age, data.gender], ', '), data.city], ' · ')}</p>
              <p>{data.patient_id}</p>
              <div className='capitalize'>{data?.health_matrix?.center}</div>
            </div>
            <div className='d-flex'>
              {data?.adherence?.adherence_range && <StatusTag title={data?.adherence?.adherence_range} icon={adherenceIcon} />}
              {data?.channel && <StatusTag title={handleChannel(data.channel).title} icon={handleChannel(data.channel).icon} />}
              {data?.care_plan?.title && <StatusTag title={data.care_plan?.title} icon={carePlan} />}
            </div>
            <div className='pt-2 social-block'>
              <img src={whatsapp} className='icon' />
              {data?.phone}
            </div>
            <div className='pt-2 social-block'>
              <img src={whatsapp} className='icon' />
              {data?.secondary_phone_num}
            </div>
            <div className='pt-2 social-block'>
              <img src={email} className='icon' />
              <a href={'mailto:' + data?.email}>{data?.email}</a>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleSubStatus = (subStatus) => {
    const newFormData = { sub_status: subStatus };
    ApiClient.createPatientStatus(newFormData, id)
      .then((res) => {
        getPatientData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onBoardingStatus = [
    { title: 'Show', id: 'show' },
    { title: 'No Show', id: 'no_show' },
  ];

  return (
    <div className='d-flex userProfilePart'>
      <div className='card w-75 m-2 userProfileDetails no-border'>{renderPatientInfo()}</div>
      <div ref={containerRef} className='card memberShipDetails w-25 m-2  no-border'>
        <div className='card-body memberShipcard-body pb-0'>
          <StatusCard
            patientUpdate
            setUpdateStatus={setEditStatus}
            onboardingStatus
            updateStatus={editStatus}
            status={status}
            setStatus={setStatus}
            getPatientData={getPatientData}
            badgeTitle={data?.status?.title}
            defaultStatus='Select Status'
          />
          <div className='renderStatusbtn mt-3'>
            <div className='d-flex status-change justify-content-between pe-4'>
              {editSubStatus ? (
                <Dropdown className='capitalize pointer' title={data?.sub_status || 'Sub Status'}>
                  {subStatus?.map((item) => {
                    return (
                      <Dropdown.Item
                        onClick={() => {
                          const windowConfirm = window.confirm('Are you sure you want to change status?');
                          if (windowConfirm) {
                            handleSubStatus(item.title);
                          }
                          setEditSubStatus(false);
                        }}
                      >
                        {item.title}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown>
              ) : (
                <>
                  <span className={'d-flex align-items-center  badge capitalize' + (data?.sub_status ? ' bg-info' : ' defaultbadge')}>
                    {data?.sub_status || 'Sub Status'}
                  </span>
                  <CommonToolTip
                    placement={'top'}
                    item={<img className='pointer' onClick={() => setEditSubStatus(true)} src={editTheme} />}
                    showValue={'Edit Sub Status'}
                  />
                </>
              )}
            </div>
          </div>
          <div className='memberinfo-list mt-3'>
            {data?.profile_info?.map((item, index) => {
              if (item.key === 'next_followup_date') {
                return (
                  <DateCards
                    nextCheckIn
                    prescriptionData={prescriptionData}
                    data={item}
                    title={item.question}
                    value={item.answer}
                    index={index}
                    getPatientData={getPatientData}
                    isTimeShow={item.type == 'date_time'}
                    isEditable={!item?.answer}
                    calendarAlign={'auto'}
                  />
                );
              } else if (item.key == 'pms_consult_date') {
                return (
                  <DateCards
                    data={item}
                    title={item.question}
                    value={item.answer}
                    index={index}
                    getPatientData={getPatientData}
                    isTimeShow={item.type == 'date_time'}
                    isEditable
                    previousDateEnable={true}
                    calendarAlign={'auto'}
                  />
                );
              } else {
                if (profileQuestionkeys.includes(item.key)) {
                  return (
                    <DateCards
                      data={item}
                      title={item.question}
                      value={item.answer}
                      index={index}
                      getPatientData={getPatientData}
                      isTimeShow={item.type == 'date_time'}
                      isEditable
                      calendarAlign={'auto'}
                    />
                  );
                }
              }
            })}
          </div>
          {data?.prc_done_at && (
            <div>
              PRC Done date:
              <br />
              {dateFormat(data?.prc_done_at)}&nbsp;{timeFormat(data?.prc_done_at)}
            </div>
          )}

          {data?.crp_booked_at && (
            <div>
              <br />
              CRP Booked Date:
              <br />
              {dateFormat(data?.crp_booked_at)}&nbsp;{timeFormat(data?.crp_booked_at)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PatientProfile;

import useApiManager from 'networking/ApiManager';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGetCockpitDataQuery } from 'redux/cockpitSlice';
import { setAlerts } from 'redux/Slices';
import { upload } from 'res/images';
import { DatePicker } from 'rsuite';
import { BorderBtnWithBg, CommonBorderBox, CoreButton, CoreInputBox, Select2DropDown, TagsShow } from 'whealth-core-web/components';
import { dateFormatStringSingleDate } from 'whealth-core-web/components/Helper';
import QuestionsList from './QuestionsList';
import inputFields from '../inputFields.json';

function AddPatientForm(props) {
  const { createPatient, errors, patientData, getPatientData, setErrorMessage, profileQuestions, selectedLocation } = props;
  const inputRef = useRef();
  const ApiClient = useApiManager();
  const { id } = useParams();
  const [formData, setFormData] = useState({});
  const [image, setImage] = useState('');
  const [serverImage, setServerImage] = useState('');
  const locations = useSelector((state) => state.sliceReducer.locations);
  const [isNivanCare, setIsNivanCare] = useState(false);
  const [careManagersList, setCareManagersList] = useState([]);
  const [revenueManagersList, setRevenueManagersList] = useState([]);
  const [carePlanList, setPlanList] = useState([]);
  const [carePlanOnchangeId, setPlanOnchangeId] = useState('');
  const [isCarePlan, setIsCarePlan] = useState('');
  const [isValidError, setIsValidError] = useState('');
  const [inputDataObj, setinputDataObj] = useState([]);
  const [ageLimitError, setAgeLimitError] = useState('');
  const [allFilteredQuestion, setAllFilteredQuestion] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    getPatientData();
    const getCareManagers = async () => {
      const res = await ApiClient.getCareManagers();
      let tempList = [];
      res.data.map((item, index) => {
        tempList.push({ ...item, title: item.full_name });
      });
      setCareManagersList(tempList);
    };

    const getCarePlans = async () => {
      const res = await ApiClient.getCarePlan();
      let tempList = [];
      res.data.map((item, index) => {
        tempList.push({ ...item, title: item.title });
      });
      setPlanList(tempList);
    };
    getCarePlans();
    console.log('values', locations, formData);
    // getCareManagers();
  }, []);
  useEffect(() => {
    if (formData?.locations) {
      getManagersByClinic(formData?.locations);
      getRevenueManagersByClinic(formData?.locations);
    } else {
      setFormData({ ...formData, care_manager_id: '' });
    }
  }, [formData?.locations]);
  let subSourceKeysObj = inputFields?.subSourceQuestion?.keys || {};
  let pastHistoryKeysObj = inputFields?.pastTreatmentHistory || {};
  let surgeonKeysObj = inputFields?.surgeon || {};

  let subSourceKeys = Object.keys(subSourceKeysObj).map((key) => {
    return subSourceKeysObj[key];
  });
  let pastHistoryKeys = Object.keys(pastHistoryKeysObj)
    .map((key) => {
      return pastHistoryKeysObj[key];
    })
    .join(',');
  let surgeonKeys = Object.keys(surgeonKeysObj)
    .map((key) => {
      return surgeonKeysObj[key];
    })
    .join(',');

  // useEffect(() => {
  //   console.log('subsourcechanges', inputFields?.subSourceQuestion, 'aaaaaaaa', subSourceKeysObj, 'bbbbbbb', subSourceKeys);
  // }, [inputFields?.subSourceQuestion, subSourceKeysObj, subSourceKeys]);
  useEffect(() => {
    if (profileQuestions?.length) {
      let allQuestions = [...profileQuestions];
      let filteredQuestions = allQuestions.filter(
        (item) =>
          !subSourceKeys.includes(item.key) &&
          !pastHistoryKeys?.split(',').includes(item.key) &&
          !surgeonKeys?.split(',').includes(item.key) &&
          item.key != 'insurance_type' &&
          item.key !== 'insurance_tpa'
      );
      let subSourceQuestions = allQuestions.filter((item) => subSourceKeys.includes(item.key));
      let pastTreatmentQuestions = [];
      Object.keys(pastHistoryKeysObj).map((item) => {
        pastTreatmentQuestions.push(...allQuestions.filter((item1) => pastHistoryKeysObj[item]?.split(',').includes(item1.key)));
      });

      let surgeonQuestions = allQuestions.filter((item) => surgeonKeys?.split(',').includes(item.key));

      let sourceIndex = filteredQuestions.findIndex((item) => item.key == 'source');
      if (sourceIndex !== -1) {
        subSourceQuestions.forEach((item) => filteredQuestions.splice(++sourceIndex, 0, item));

        let departmentSubsourceIndex = filteredQuestions.findIndex((item) => item.key === 'department_subsource');
        if (departmentSubsourceIndex !== -1) {
          filteredQuestions.splice(sourceIndex + 1, 0, ...filteredQuestions.splice(departmentSubsourceIndex, 1));
        }
        let droNameIndex = filteredQuestions.findIndex((item) => item.key === 'dro_name');
        if (droNameIndex !== -1) {
          filteredQuestions.splice(sourceIndex + 1, 0, ...filteredQuestions.splice(droNameIndex, 1));
        }
      }
      let insuranceCatIndex = filteredQuestions.findIndex((item) => item.key == 'insurance_category');

      if (insuranceCatIndex != -1) {
        let insuranceTypeQuestions = allQuestions.filter((item) => item.key == 'insurance_type');
        let insuranceTPAIndex = allQuestions.filter((item) => item.key == 'insurance_tpa');
        filteredQuestions.splice(insuranceCatIndex + 1, 0, insuranceTypeQuestions[0]);
        filteredQuestions.splice(insuranceCatIndex + 2, 0, insuranceTPAIndex[0]);
      }

      let pastTreatmentIndex = filteredQuestions.findIndex((item) => item.key == 'past_treatment');
      if (pastTreatmentIndex != -1) {
        pastTreatmentQuestions.map((item) => {
          filteredQuestions.splice(pastTreatmentIndex + 1, 0, item);
          if (item.key == 'surgeon') {
            surgeonQuestions.map((item) => {
              filteredQuestions.splice(pastTreatmentIndex + 1 + 1, 0, item);
            });
          }
        });
      }

      const filteredQuestionsIndex = filteredQuestions?.findIndex((item) => item?.key == 'channel');
      if (filteredQuestionsIndex != -1 && !filteredQuestions[filteredQuestionsIndex]?.answer) {
        let newObject = { ...filteredQuestions[filteredQuestionsIndex] };
        filteredQuestions[filteredQuestionsIndex] = { ...newObject, answer: 'Walkin' };
      }
      // console.log('subsourcechanges ------------->', filteredQuestions);

      setAllFilteredQuestion([...filteredQuestions]);
      setinputDataObj([...filteredQuestions]);
      // setinputDataObj([...profileQuestions]);
    }
  }, [profileQuestions]);

  const getCockpitData = useGetCockpitDataQuery();
  let configData = getCockpitData?.data?.data?.business_config;

  useEffect(() => {
    if (configData) {
      configData.map((item) => {
        if (item.key == 'enable_summary' && item.isActive) {
          setIsNivanCare(item.isActive);
        }
        if (item.key == 'care_plan') {
          setIsCarePlan(item.isActive);
        }
      });
    }
  }, [configData]);
  useEffect(() => {
    setServerImage(patientData?.profile_url);
    let temp = patientData?.locations?.length
      ? {
          ...patientData,
          locations: patientData?.locations[0],
          care_manager_id: patientData?.care_manager?.id,
          revenue_manager_id: patientData?.revenue_manager?.id,
        }
      : { ...patientData, care_manager_id: patientData?.care_manager?.id, revenue_manager_id: patientData?.revenue_manager?.id };

    setFormData(temp);
  }, [patientData]);

  // const handleChange = (key, val) => {
  //   let newFormData = { ...formData, [key]: val };
  //   setFormData({ ...newFormData });
  //   if (key === 'care_plan_id') {
  //     let newFormData = { ...formData, [key]: val, c_plan_activation_date: dateFormatStringSingleDate(new Date()) };
  //     setFormData({ ...newFormData });
  //   }
  // };
  const getManagersByClinic = async () => {
    await ApiClient.getCareManagersByClinic(formData?.locations)
      .then((res) => {
        let tempList = [];
        res.data.map((item, index) => {
          tempList.push({ ...item, title: item.full_name });
        });
        setCareManagersList(tempList);
        // setCareManagersList(res?.data);
      })
      .catch((error) => console.log(error));
  };
  const getRevenueManagersByClinic = async () => {
    await ApiClient.getRevenueManagersByClinic(formData?.locations)
      .then((res) => {
        let tempList = [];
        res.data.map((item, index) => {
          tempList.push({ ...item, title: item.full_name });
        });
        setRevenueManagersList(tempList);
      })
      .catch((error) => console.log(error));
  };
  const handleChange = (key, val) => {
    if (key === 'age') {
      const ageValue = parseInt(val, 10);
      if (ageValue > 150) {
        let newFormData = { ...formData, [key]: '' };
        setFormData({ ...newFormData });
        return setAgeLimitError('Please enter below age of 150.');
      } else {
        setAgeLimitError('');
      }
    }
    let newFormData = { ...formData, [key]: val };
    if (key === 'locations') {
      setFormData({ ...newFormData, care_manager_id: '', revenue_manager_id: '' });
    } else {
      setFormData({ ...newFormData });
    }
    if (key === 'care_plan_id') {
      let newFormData = { ...formData, [key]: val, c_plan_activation_date: dateFormatStringSingleDate(new Date()) };
      setFormData({ ...newFormData });
    }
  };

  const getCarePlanSearch = async (query) => {
    const res = await ApiClient.getCareSearch(query);
    setPlanList(res.data);
  };
  const genderData = [
    { title: 'Male', id: 'male' },
    { title: 'Female', id: 'female' },
    { title: 'Other', id: 'other' },
  ];

  const CarekitData = [
    { title: 'Yes', id: true },
    { title: 'No', id: false },
  ];
  console.log('dropdown values', Object.values([...locations, ...selectedLocation].reduce((acc, item) => ((acc[item.id] = item), acc), {})));
  const inputFieldsData = [
    {
      key: 'full_name',
      placeholder: 'Full Name',

      label: 'Name',
      viewType: 'text',
      maxLength: 120,
      message: errors?.full_name,
      retuired: true,
    },
    {
      key: 'phone',
      label: 'Mobile No.',
      placeholder: '10 Digit Mobile Number',
      viewType: 'text',
      retuired: true,
      maxLength: 10,
      validateNumbers: true,
      showCode: true,
      message: errors?.phone,
    },
    {
      key: 'secondary_phone_num',
      label: 'Alt Mobile No.',
      placeholder: '10 Digit Mobile Number',
      viewType: 'text',
      retuired: false,
      maxLength: 10,
      validateNumbers: true,
      showCode: true,
      message: errors?.secondary_phone_num,
    },
    {
      key: 'email',
      placeholder: 'Email Address',
      label: 'Email Address',
      viewType: 'text',
      message: errors?.email,
    },
    {
      key: 'gender',
      placeholder: 'Select Gender',
      label: 'Select Gender',
      // viewType: 'dropDown',
      data: genderData,
      retuired: true,
      message: errors?.gender,
    },
    {
      key: 'address',
      label: 'Residence address',
      placeholder: 'Residence Address',
      viewType: 'text',
      message: errors?.residence_address,
    },
    {
      key: 'city',
      placeholder: 'City',
      label: ' City',
      viewType: 'text',
      message: errors?.city,
    },

    {
      key: 'state',
      placeholder: 'State',
      label: 'state',
      viewType: 'text',
      message: errors?.state,
      maxLength: 50,
    },

    {
      key: 'pincode',
      placeholder: 'Pincode',
      label: 'pincode',
      viewType: 'text',
      message: errors?.pincode,
      maxLength: 6,
      validateNumbers: true,
    },

    {
      key: 'age',
      placeholder: 'Age',
      label: 'Age',
      retuired: true,
      viewType: 'text',
      message: ageLimitError ? ageLimitError : errors?.profile_age,
      validateNumbers: true,
      validateMax: 150,
    },
    {
      key: 'locations',
      placeholder: 'Select Hospital',
      label: 'Hospital',
      viewType: 'dropDown',
      // data: locations,
      data: [...new Map([...locations, ...selectedLocation].map((item) => [item.id, item])).values()],
      multiple: true,
      retuired: true,
      message: errors?.locations,
    },
    isCarePlan && {
      key: 'care_plan_id',
      placeholder: 'Select Care Plan',
      label: 'Care Plan',
      viewType: 'dropDown',
      data: carePlanList,
      message: errors?.care_plan_id,
    },

    {
      key: 'care_manager_id',
      placeholder: 'Select Care Managers',
      label: 'Care Manager',
      viewType: 'dropDown',
      data: careManagersList,
      multiple: true,
      message: errors?.care_manager_id,
      retuired: true,
      disabled: !formData?.locations,
    },
    {
      key: 'revenue_manager_id',
      placeholder: 'Select Revenue Managers',
      label: 'Revenue Manager',
      viewType: 'dropDown',
      data: revenueManagersList,
      multiple: true,
      message: errors?.revenue_manager_id,
      retuired: true,
      disabled: !formData?.locations,
    },
    (formData?.care_plan_id || carePlanOnchangeId) &&
      isCarePlan && {
        key: 'c_plan_activation_date',
        placeholder: 'Care Plan Activation Date',
        label: ' Care Plan Activation Date',
        viewType: 'ActivationDate',
        userNotSelectData: 'pastDate',
      },
  ];

  const renderFormData = (showData) => {
    return showData.map((item, index) => {
      const showHTML = item.html;
      delete item.html;
      if (item.viewType == 'text') {
        return (
          <div key={index} className='articleInputDiv d-lg-flex mt-3 ' style={item.singleView && { width: '100%' }}>
            <div className='w-100 d-flex '>
              <div className='w-100 '>
                <CoreInputBox
                  {...item}
                  type={item.viewType}
                  labelStyle={{ fontWeight: 'var(--lightFont)' }}
                  setValue={(data) => handleChange(item.key, data)}
                  value={formData[item.key]}
                  showMSG={item.message}
                  msgStyle={{ color: 'red' }}
                />
              </div>
              {showHTML && showHTML()}
            </div>
          </div>
        );
      }
      if (item.viewType == 'date') {
        return (
          <div className='mt-4 articleInputDiv'>
            <div>
              <label class='inputlabel'>{item.label}</label>
            </div>
            <DatePicker
              disabledDate={(date) => {
                if (new Date(date) > new Date()) {
                  return true;
                } else {
                  return false;
                }
              }}
              onClean={() => {
                let newFormData = { ...formData, ['date_of_birth']: null };
                setFormData({ ...newFormData });
              }}
              style={{ width: '100%' }}
              placement='bottomStart'
              format='yyyy-MM-dd'
              placeholder='YYYY-MM-DD'
              value={formData[item.key] ? new Date(formData[item.key]) : null}
              calendarDefaultDate={formData[item.key] ? new Date(formData[item.key]) : null}
              onSelect={(data) => handleChange(item.key, dateFormatStringSingleDate(data))}
            />
          </div>
        );
      } else if (item.viewType == 'ActivationDate') {
        return (
          <div className='mt-3 articleInputDiv'>
            <div>
              <label class='inputlabel'>{item.label}</label>
            </div>
            <DatePicker
              disabledDate={(date) => {
                if (new Date(date) < new Date().setHours(0, 0, 0)) {
                  return true;
                } else {
                  return false;
                }
              }}
              onClean={() => {
                let newFormData = { ...formData, ['c_plan_activation_date']: dateFormatStringSingleDate(new Date()) };
                setFormData({ ...newFormData });
              }}
              style={{ width: '100%' }}
              placement='bottomStart'
              format='yyyy-MM-dd'
              placeholder='YYYY-MM-DD'
              value={formData[item.key] ? new Date(formData[item.key]) : new Date()}
              calendarDefaultDate={formData[item.key] ? new Date(formData[item.key]) : new Date()}
              onSelect={(data) => handleChange(item.key, dateFormatStringSingleDate(data))}
            />
          </div>
        );
      } else if (item.viewType == 'dropDown') {
        return (
          <div key={index} className=' articleInputDiv d-lg-flex mt-3' style={item.singleView && { width: '100%' }}>
            <div className='w-100'>
              <Select2DropDown
                isValidation
                onSearch={(data) => getCarePlanSearch(data)}
                searchable={item.key !== 'gender' && true}
                data={
                  !item.data.find((menu) => formData.care_plan?.id == menu.id) && formData.care_plan?.id && item?.key != 'care_manager_id'
                    ? [...item.data, formData?.care_plan]
                    : item.data
                }
                retuired={item.retuired}
                placeholder={item.placeholder}
                label={item.label}
                onSelect={(data, dataObj) => {
                  handleChange(item.key, data, item);
                }}
                value={formData[item.key] || ''}
                showMSG={item.message}
                msgStyle={{ color: 'red' }}
                valueKey={item.setKey}
                disabled={item?.disabled}
              />
            </div>
          </div>
        );
      } else if (item.key == 'gender') {
        return (
          <div key={index} className=' articleInputDiv d-lg-flex mt-3' style={item.singleView && { width: '100%' }}>
            <div className='w-100'>
              <Select2DropDown
                isValidation
                onSearch={(data) => getCarePlanSearch(data)}
                searchable={item.key !== 'gender' && true}
                data={item.data}
                retuired={item.retuired}
                placeholder={item.placeholder}
                label={item.label}
                onSelect={(data, dataObj) => {
                  handleChange(item.key, data, item);
                }}
                value={formData[item.key] || ''}
                showMSG={item.message}
                msgStyle={{ color: 'red' }}
                valueKey={item.setKey}
              />
            </div>
          </div>
        );
      } else if (item.viewType == 'tags') {
        return (
          <div key={index} className='articleInputDiv'>
            <TagsShow
              details={item.detail}
              tagsStyle={item.tagsStyle}
              data={item.data}
              keepSearchTerm={true}
              retuired={item.retuired}
              labelStyle={{ margin: '24px 0 4px 0', fontWeight: 'var(--lightWeight)' }}
              placeholder={item.placeholder}
              label={item.label}
              setValue={(data) => handleChange(item.key, data)}
              value={formData[item.key]}
              showMSG={item.message}
              msgStyle={{ color: 'red' }}
            />
          </div>
        );
      }
    });
  };

  const updateProfilePhoto = async (imageURL) => {
    // setIsLoading(true);
    const newFormData = new FormData();
    imageURL && typeof imageURL != 'string' && newFormData.append('profile_url', imageURL);
    newFormData.append('full_name', patientData?.full_name);
    newFormData.append('phone', patientData?.phone);

    if (id) {
      ApiClient.updatePatient(newFormData, id)
        .then(async (res) => {
          // setIsLoading(false);
          // navigate(`/patient/${id}/dashboard`);
          dispatch(setAlerts({ patient: { title: 'Patient Updated Successfully', type: 'alert-success' } }));
        })
        .catch((err) => {
          // setIsLoading(false);
          // if (err.response.status == 413) {
          //   setShowAlert('image size is too large');
          // } else if (err.response.status == 500) {
          //   setShowAlert('Internal server Error');
          // } else {
          //   err?.response?.data?.detail && setShowAlert(err?.response?.data?.detail);
          //   // serErrors(err.response.data.errors);
          // }
          // setShowAlert(err.response.data.detail);
        });
    }
  };

  return (
    <div>
      <div className='d-flex teamAddForm' style={{ gap: '32px', justifyContent: 'space-between' }}>
        <div className='teamFormDiv'>
          <div className='semiBoldWeight'>Basic Info</div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              createPatient(formData, inputDataObj);
            }}
          >
            <div className='teamFormDiv'>
              {renderFormData(inputFieldsData)}
              <div className='w-100 mt-4'>
                <div className='semiBoldWeight mt-2 mb-2'>Profile Questions</div>
                <QuestionsList
                  data={allFilteredQuestion || []}
                  setData={setAllFilteredQuestion}
                  inputDataObj={inputDataObj}
                  setinputDataObj={setinputDataObj}
                  errors={errors?.profile_answers || []}
                />
              </div>
              <CoreButton
                onClick={() => {
                  const updatedFormData = {
                    ...formData,
                    // care_manager_id: formData.care_manager?.id || null,
                    care_manager_id: formData?.care_manager_id || null,
                    revenue_manager_id: formData?.revenue_manager_id || null,
                  };
                  createPatient(updatedFormData, inputDataObj);

                  const filteredQuestionsIndex = inputDataObj?.findIndex((item) => item?.key == 'channel');
                  if (filteredQuestionsIndex != -1 && !inputDataObj[filteredQuestionsIndex]?.answer) {
                    inputDataObj[filteredQuestionsIndex].answer = 'Walkin';
                  }
                }}
                type='submit'
                title='Save Details'
                customDivStyle={{ width: '100%' }}
              />
            </div>
            <button type='submit' className='d-none'></button>
          </form>
        </div>
        {id ? (
          <div>
            <div>
              <div className='profilePhotoStyle'>
                <input
                  ref={inputRef}
                  type='file'
                  className='d-none'
                  value=''
                  accept='image/*'
                  // onChange={(e) => {
                  //   let tmpData = { ...formData };
                  //   tmpData.profile_url = e.target.files[0];
                  //   setFormData({ ...tmpData });
                  //   setImage(e.target.files[0]);
                  // }}
                  onChange={(e) => {
                    let tmpData = { ...formData };
                    tmpData.profile_url = e.target.files[0];
                    updateProfilePhoto(e.target.files[0]);
                    // setFormData({ ...tmpData });
                    setImage(e.target.files[0]);
                  }}
                />
                {image && <img src={URL.createObjectURL(image)} />}
                {serverImage && !image && <img src={serverImage} />}
                {!image && !serverImage && <div className='w-50'>Drop Profile Photo</div>}
              </div>
            </div>
            <div>
              <BorderBtnWithBg
                onClick={() => {
                  inputRef.current.click();
                }}
                className='uploadPhotoBtn'
                iconImg={upload}
                title='Upload'
              />
            </div>
          </div>
        ) : null}
      </div>
      <div></div>
    </div>
  );
}

export default AddPatientForm;

import useApiManager from 'networking/ApiManager';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { searchLight } from 'res/images';
import { CommonBorderBox, CoreButton, CoreCheckBox, CoreDropDown, CoreInputBox, DragAndDropFiles } from 'whealth-core-web/components';
import TimezoneSelect from 'react-timezone-select';
import CommonRenderCheckBox from 'whealth-core-web/components/CommonRenderCheckBox';
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import TimeKeeper from 'react-timekeeper';
import { STRING } from 'constants/String';
function WatiConfigForm(props) {
  const { error, valueObj, setValueObj, onClick, setIsLoading, logo } = props;
  const ApiClient = useApiManager();
  const [data, setData] = useState([]);
  const [showFrom, setShowFrom] = useState(false);
  const [showTo, setShowTo] = useState(false);

  useEffect(() => {
    getGoalsData();
  }, []);

  const getGoalsData = () => {
    setIsLoading(true);
    ApiClient.getGoals()
      .then((res) => {
        setIsLoading(false);
        setData(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const renderBox = () => {
    return (
      <>
        <div className='commonBorderBox settingFormOutline mb-4 pb-4'>
          <div className='formTitle'>WATI Configuration</div>

          <div className='commonBorderBox settingFormOutline mb-4 pb-4'>
            <div className='formTitle'>Account Configuration</div>
            <div className='mt-4'>
              <CoreInputBox
                showMSG={error.wati_access_token}
                placeholder='Access Token'
                value={valueObj.wati_access_token || ''}
                label='Access Token'
                setValue={(data) => {
                  setValueObj({ ...valueObj, wati_access_token: data });
                }}
              />
            </div>
            <div className='mt-4'>
              <CoreInputBox
                showMSG={error.wati_api_endpoint}
                placeholder='Wati Base URL'
                value={valueObj.wati_api_endpoint || ''}
                label='WATI Base URL'
                setValue={(data) => {
                  setValueObj({ ...valueObj, wati_api_endpoint: data });
                }}
              />
            </div>
            <div className='mt-4'>
              <CoreInputBox
                showMSG={error.wati_broadcast_name_prefix}
                placeholder='WATI Broadcast Name'
                value={valueObj.wati_broadcast_name_prefix || ''}
                label='WATI Broadcast Name'
                setValue={(data) => {
                  setValueObj({
                    ...valueObj,
                    wati_broadcast_name_prefix: data,
                  });
                }}
              />
            </div>
            <div className='mt-4'>
              <CoreInputBox
                showMSG={error.wati_api_key}
                placeholder='WATI API Key'
                value={valueObj.wati_api_key || ''}
                label='WATI API Key'
                setValue={(data) => {
                  setValueObj({ ...valueObj, wati_api_key: data });
                }}
              />
            </div>
            <div className='mt-4'>
              <CoreInputBox
                showMSG={error.wati_token_secret}
                placeholder='WATI Token Secret'
                value={valueObj.wati_token_secret || ''}
                label='WATI Token Secret'
                setValue={(data) => {
                  setValueObj({ ...valueObj, wati_token_secret: data });
                }}
              />
            </div>
          </div>

          <div className='commonBorderBox settingFormOutline mb-4 pb-4'>
            <div className='formTitle'>Goal Notification Configuration</div>
            <div className='d-flex justify-content-left'>
              <div className='w-50'>
                <CommonRenderCheckBox
                  label='Goal Assignment Notification'
                  id='flexCheckDefault3'
                  htmlFor='flexCheckDefault3'
                  onChange={(data) => {
                    setValueObj({
                      ...valueObj,
                      wati_goal_assignment_notification: !valueObj.wati_goal_assignment_notification,
                    });
                  }}
                  checked={valueObj.wati_goal_assignment_notification}
                />
              </div>
              <div className='w-50'>
                <CoreInputBox
                  showMSG={error.wati_goal_assignment_notification_template_name}
                  placeholder='Goal Assignment Notification Template Name'
                  value={valueObj.wati_goal_assignment_notification_template_name || ''}
                  label='Goal Assignment Notification Template Name'
                  setValue={(data) => {
                    setValueObj({
                      ...valueObj,
                      wati_goal_assignment_notification_template_name: data,
                    });
                  }}
                />
              </div>
            </div>
            <div className='d-flex justify-content-between mt-4'>
              <div className='w-50'>
                <CommonRenderCheckBox
                  label='Goal Reminder Notification'
                  id='flexCheckDefault4'
                  htmlFor='flexCheckDefault4'
                  onChange={(data) => {
                    setValueObj({
                      ...valueObj,
                      wati_goal_reminder_notification: !valueObj.wati_goal_reminder_notification,
                    });
                  }}
                  checked={valueObj.wati_goal_reminder_notification}
                />
              </div>

              <div className='w-50'>
                <CoreInputBox
                  showMSG={error.wati_goal_reminder_notification_template_name}
                  placeholder='Goal Reminder Notification Template Name'
                  value={valueObj.wati_goal_reminder_notification_template_name || ''}
                  label='Goal Reminder Notification Template Name'
                  setValue={(data) => {
                    setValueObj({
                      ...valueObj,
                      wati_goal_reminder_notification_template_name: data,
                    });
                  }}
                />
              </div>
            </div>
          </div>

          <div className='commonBorderBox settingFormOutline mb-4 pb-4'>
            <div className='formTitle'>{STRING.NEXT_PMS_CHECKIN_DATE} Configuration</div>
            <div className='d-flex justify-content-left'>
              <div className='w-50'>
                <CommonRenderCheckBox
                  label={STRING.NEXT_PMS_CHECKIN_DATE}
                  id='flexCheckDefault5'
                  htmlFor='flexCheckDefault5'
                  onChange={(data) => {
                    setValueObj({
                      ...valueObj,
                      next_pms_checkin_date_notification: !valueObj.next_pms_checkin_date_notification,
                    });
                  }}
                  checked={valueObj.next_pms_checkin_date_notification}
                />
              </div>
              <div className='w-50'>
                <CoreInputBox
                  showMSG={error.next_pms_checkin_date_notification_template_name}
                  placeholder={STRING.NEXT_PMS_CHECKIN_DATE + STRING.NOTIFICATION_TEMPLATE_NAME}
                  value={valueObj.next_pms_checkin_date_notification_template_name || ''}
                  label={STRING.NEXT_PMS_CHECKIN_DATE + STRING.NOTIFICATION_TEMPLATE_NAME}
                  setValue={(data) => {
                    setValueObj({
                      ...valueObj,
                      next_pms_checkin_date_notification_template_name: data,
                    });
                  }}
                />
              </div>
            </div>
          </div>

          <div className='commonBorderBox settingFormOutline mb-4 pb-4'>
            <div className='formTitle'>{STRING.CONVERSION_LOG_DAY_NOTIFICATION('')} Configuration</div>
            <div className='d-flex justify-content-left'>
              <div className='w-50'>
                <CommonRenderCheckBox
                  label={STRING.CONVERSION_LOG_DAY_NOTIFICATION('1')}
                  id='flexCheckDefault6'
                  htmlFor='flexCheckDefault6'
                  onChange={(data) => {
                    setValueObj({
                      ...valueObj,
                      conversion_log_day_1_notification: !valueObj.conversion_log_day_1_notification,
                    });
                  }}
                  checked={valueObj.conversion_log_day_1_notification}
                />
              </div>
              <div className='w-50'>
                <CoreInputBox
                  showMSG={error.conversion_log_day_1_notification_template_name}
                  placeholder={STRING.CONVERSION_LOG_DAY_NOTIFICATION('1') + STRING.NOTIFICATION_TEMPLATE_NAME}
                  value={valueObj.conversion_log_day_1_notification_template_name || ''}
                  label={STRING.CONVERSION_LOG_DAY_NOTIFICATION('1') + STRING.NOTIFICATION_TEMPLATE_NAME}
                  setValue={(data) => {
                    setValueObj({
                      ...valueObj,
                      conversion_log_day_1_notification_template_name: data,
                    });
                  }}
                />
              </div>
            </div>
            <br />
            <div className='d-flex justify-content-left'>
              <div className='w-50'>
                <CommonRenderCheckBox
                  label={STRING.CONVERSION_LOG_DAY_NOTIFICATION('2')}
                  id='flexCheckDefault7'
                  htmlFor='flexCheckDefault7'
                  onChange={(data) => {
                    setValueObj({
                      ...valueObj,
                      conversion_log_day_2_notification: !valueObj.conversion_log_day_2_notification,
                    });
                  }}
                  checked={valueObj.conversion_log_day_2_notification}
                />
              </div>
              <div className='w-50'>
                <CoreInputBox
                  showMSG={error.conversion_log_day_2_notification_template_name}
                  placeholder={STRING.CONVERSION_LOG_DAY_NOTIFICATION('2') + STRING.NOTIFICATION_TEMPLATE_NAME}
                  value={valueObj.conversion_log_day_2_notification_template_name || ''}
                  label={STRING.CONVERSION_LOG_DAY_NOTIFICATION('2') + STRING.NOTIFICATION_TEMPLATE_NAME}
                  setValue={(data) => {
                    setValueObj({
                      ...valueObj,
                      conversion_log_day_2_notification_template_name: data,
                    });
                  }}
                />
              </div>
            </div>
            <br />
            <div className='d-flex justify-content-left'>
              <div className='w-50'>
                <CommonRenderCheckBox
                  label={STRING.CONVERSION_LOG_DAY_NOTIFICATION('3')}
                  id='flexCheckDefault8'
                  htmlFor='flexCheckDefault8'
                  onChange={(data) => {
                    setValueObj({
                      ...valueObj,
                      conversion_log_day_3_notification: !valueObj.conversion_log_day_3_notification,
                    });
                  }}
                  checked={valueObj.conversion_log_day_3_notification}
                />
              </div>
              <div className='w-50'>
                <CoreInputBox
                  showMSG={error.conversion_log_day_3_notification_template_name}
                  placeholder={STRING.CONVERSION_LOG_DAY_NOTIFICATION('3') + STRING.NOTIFICATION_TEMPLATE_NAME}
                  value={valueObj.conversion_log_day_3_notification_template_name || ''}
                  label={STRING.CONVERSION_LOG_DAY_NOTIFICATION('3') + STRING.NOTIFICATION_TEMPLATE_NAME}
                  setValue={(data) => {
                    setValueObj({
                      ...valueObj,
                      conversion_log_day_3_notification_template_name: data,
                    });
                  }}
                />
              </div>
            </div>
            <br />
            <div className='d-flex justify-content-left'>
              <div className='w-50'>
                <CommonRenderCheckBox
                  label={STRING.CONVERSION_LOG_DAY_NOTIFICATION('7')}
                  id='flexCheckDefault9'
                  htmlFor='flexCheckDefault9'
                  onChange={(data) => {
                    setValueObj({
                      ...valueObj,
                      conversion_log_day_7_notification: !valueObj.conversion_log_day_7_notification,
                    });
                  }}
                  checked={valueObj.conversion_log_day_7_notification}
                />
              </div>
              <div className='w-50'>
                <CoreInputBox
                  showMSG={error.conversion_log_day_7_notification_template_name}
                  placeholder={STRING.CONVERSION_LOG_DAY_NOTIFICATION('7') + STRING.NOTIFICATION_TEMPLATE_NAME}
                  value={valueObj.conversion_log_day_7_notification_template_name || ''}
                  label={STRING.CONVERSION_LOG_DAY_NOTIFICATION('7') + STRING.NOTIFICATION_TEMPLATE_NAME}
                  setValue={(data) => {
                    setValueObj({
                      ...valueObj,
                      conversion_log_day_7_notification_template_name: data,
                    });
                  }}
                />
              </div>
            </div>
            <br />
            <div className='d-flex justify-content-left'>
              <div className='w-50'>
                <CommonRenderCheckBox
                  label={STRING.CONVERSION_LOG_DAY_NOTIFICATION('15 - 30')}
                  id='flexCheckDefault10'
                  htmlFor='flexCheckDefault10'
                  onChange={(data) => {
                    setValueObj({
                      ...valueObj,
                      conversion_log_day_15_to_30_notification: !valueObj.conversion_log_day_15_to_30_notification,
                    });
                  }}
                  checked={valueObj.conversion_log_day_15_to_30_notification}
                />
              </div>
              <div className='w-50'>
                <CoreInputBox
                  showMSG={error.conversion_log_day_15_to_30_notification_template_name}
                  placeholder={STRING.CONVERSION_LOG_DAY_NOTIFICATION('15 - 30') + STRING.NOTIFICATION_TEMPLATE_NAME}
                  value={valueObj.conversion_log_day_15_to_30_notification_template_name || ''}
                  label={STRING.CONVERSION_LOG_DAY_NOTIFICATION('15 - 30') + STRING.NOTIFICATION_TEMPLATE_NAME}
                  setValue={(data) => {
                    setValueObj({
                      ...valueObj,
                      conversion_log_day_15_to_30_notification_template_name: data,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div style={{ gap: '10px' }} className='d-flex w-100 justify-content-between '>
        <div className='w-100 '>{renderBox()}</div>
      </div>
      <CoreButton onClick={onClick} title={'Save'} />
    </>
  );
}

export default WatiConfigForm;
